import { Fragment, useEffect, useState } from 'react';
import Banner from '../components/Banner';
import Layout from '../components/Layout';
import API, { graphqlOperation } from '@aws-amplify/api';
import {
  createInquiryPipeline,
  getAccountPublic,
  getOrderFormBySlug,
  getUserAccess,
  listAllUsersByAccount,
} from '../constants/graphql';
import Stepper from '../components/Home/Questions/Stepper';
import { useParams, useNavigate } from 'react-router-dom';
import { FormDetails, Question } from '../models/Question.interface';
import { generateQuestion } from '../utils/question';
import ProfileImg from '../components/ProfileImg';
import HomeSpinner from '../components/HomeSpinner';
import Button from '../components/Button';
import { OrderFormAvailability } from '../models/Account.interface';

export default function Home() {
  const params = useParams();

  let navigate: any = useNavigate();

  const [orderFormQuestions, setOrderFormQuestions] = useState<Question[]>([]);
  const [orderFormId, setOrderFormId] = useState<string>('');
  const [formDetails, setFormDetails] = useState<FormDetails>({
    title: '',
    subtitle: '',
    companyName: '',
  });
  const [banner, setBanner] = useState<string>('');
  const [logoRef, setLogoRef] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orderFormAvailabilityDates, setOrderFormAvailabilityDates] = useState<
    OrderFormAvailability[]
  >([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [accId, setAccId] = useState<string>('');
  const [accountInfo, setAccountInfo] = useState<any>();
  const [isProUser, setIsProUser] = useState<boolean>(false);

  const [excludeDateRange, setExcludeDateRange] = useState<Date[]>([]);
  // for O(1) lookup
  const [excludeDateSet, setExcludeDateSet] = useState(new Set());

  // this is the invalid object that will be used to show each item that is invalid and why
  const [invalid, setInvalid] = useState<any>({});

  const layoutClass =
    'container mx-auto mx-auto flex flex-col shadow-lg -mt-32 rounded-2xl overflow-hidden mb-16';
  const conditionalLayoutClass = `${layoutClass} ${isLoading ? 'hidden' : ''}`;

  async function getOrderForm(slug: string) {
    try {
      setIsLoading(true);
      const orderForm: any = await API.graphql(
        graphqlOperation(getOrderFormBySlug(), { slug })
      );
      const accInfo = orderForm?.data?.getOrderFormBySlug?.items;
      if (accInfo && accInfo?.length > 0) {
        const accId = accInfo[0].accountId;
        const orderStatus = accInfo[0]?.orderStatus;

        setAccId(accId);
        setAccountInfo(accInfo[0]);

        const accRes = (await API.graphql(
          graphqlOperation(getAccountPublic(), { accountId: accId })
        )) as { data: any };

        const questionsItems = orderForm?.data?.getOrderFormBySlug?.items;
        const questions =
          questionsItems?.length > 0 ? questionsItems[0].questions : [];
        setOrderFormQuestions(questions);

        if (
          orderStatus === 'DEACTIVATED' ||
          !questionsItems ||
          questionsItems?.length === 0
        ) {
          navigate(`/notavailable/${params.slug}`);
        } else {
          setOrderFormId(questionsItems[0].orderFormId);
          setBanner(questionsItems[0].bannerPhotoRef || '');

          let profilePhoto = accRes?.data?.getAccountPublic?.company?.logoRef;
          if (!!profilePhoto) {
            setLogoRef(profilePhoto);
          } else {
            // Since users and companies are 1:1 in this case we can safely assume that the first returned will be the one to display
            // I fought with queries for way too long and settled on this one, if you can make one that just grabs defaultPhotoRefId without using this call that'd be ideal
            const profilePhotoRes: any = await API.graphql(
              graphqlOperation(listAllUsersByAccount(), {
                accountId: accId,
              })
            );
            setLogoRef(
              profilePhotoRes?.data?.listAllUsersByAccount?.items[0]
                ?.defaultPhotoRefId || ''
            );
          }

          if (questionsItems && questionsItems?.length > 0) {
            setFormDetails({
              title: questionsItems[0].title,
              subtitle: questionsItems[0].description,
              companyName: accRes?.data?.getAccountPublic?.company?.name || '',
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      navigate(`/notavailable/${params.slug}`);
    }
  }

  useEffect(() => {
    if (params.slug) {
      getOrderForm(params.slug);
    }
  }, [params]);

  useEffect(() => {
    if (!!accountInfo?.availability) {
      setOrderFormAvailabilityDates(accountInfo.availability);
    }
  }, [accountInfo]);

  useEffect(() => {
    if (!!accId) {
      checkUserStatus();
    }
  }, [accId]);

  useEffect(() => {
    if (!!orderFormAvailabilityDates) {
      getExcludeDateRange();
    }
    getExcludeDateRange();
  }, [orderFormAvailabilityDates]);

  const getExcludeDateRange = () => {
    let excludedDates: Date[] = [];
    let excludedDatesSet = new Set();

    if (orderFormAvailabilityDates) {
      for (const range of orderFormAvailabilityDates) {
        const startDate = new Date(range.startDate);
        const endDate = new Date(range.endDate);
        const currentDate = startDate;
        while (currentDate < endDate) {
          // set time to 0
          const dateToInclude = new Date(
            new Date(currentDate).setHours(0, 0, 0, 0)
          );
          excludedDates.push(dateToInclude);
          excludedDatesSet.add(dateToInclude.toISOString());
          currentDate.setDate(currentDate.getDate() + 1);
        }

        // set time to 0
        const dateToInclude = new Date(new Date(endDate).setHours(0, 0, 0, 0));
        excludedDates.push(dateToInclude);
        excludedDatesSet.add(dateToInclude.toISOString());
      }
    }
    setExcludeDateRange(excludedDates);
    setExcludeDateSet(excludedDatesSet);
  };

  async function checkUserStatus() {
    try {
      const isProUserReturn = (await API.graphql(
        graphqlOperation(getUserAccess(), { accountId: accId })
      )) as { data: any };

      if (
        isProUserReturn?.data?.getAccountSubscriptionFromAccountId?.items[0]
          ?.hasAccess
      ) {
        setIsProUser(true);
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   *
   * result: boolean
   * reason: number, currently only 1 indicates an invalid date otherwise it's default, expand in future?
   *
   */
  function validate() {
    // reset validation object before checking again
    setInvalid({});

    let validated = true;
    let isDateInvalid = false;
    let index: number = 0;
    let invalidObject: any = {};
    for (const question of orderFormQuestions) {
      console.log(question);
      if (question.required || question.systemQuestion) {
        const questionType = question.questionType;
        isDateInvalid =
          !question.answer ||
          (questionType === 'DateSelection' &&
            excludeDateSet.has(
              new Date(
                new Date(question.answer).setHours(0, 0, 0, 0)
              ).toISOString()
            ));

        const emailRegex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (
          (questionType === 'ShortAnswer' ||
            questionType === 'DateSelection' ||
            questionType === 'Text' ||
            questionType === 'Paragraph' ||
            questionType === 'FileUpload') &&
          !question.answer
        ) {
          validated = false;

          // this will always be the first so no need to get existing
          invalidObject = {
            ...invalidObject,
            [index]: ["This question can't be blank"],
          };
        }

        if (
          (questionType === 'Dropdown' || questionType === 'Choice') &&
          !question.choiceAnswers
        ) {
          invalidObject = {
            ...invalidObject,
            [index]: ['Please make a selection'],
          };

          validated = false;
        }

        if (questionType === 'DateSelection' && isDateInvalid) {
          invalidObject = {
            ...invalidObject,
            [index]: ['Selected date is invalid'],
          };
          validated = false;
        }

        if (question.systemQuestion === 'Email') {
          if (!emailRegex.test(question.answer as string)) {
            const existingError = invalidObject[index] || [];
            invalidObject = {
              ...invalidObject,
              [index]: [...existingError, 'Email is invalid'],
            };

            validated = false;
          }
        }
      }
      index++;
    }

    setInvalid(invalidObject);
    return { result: validated };
  }

  async function createInquiry() {
    try {
      const validated = validate();
      if (validated.result) {
        let body = {
          accountId: accId,
          createdAt: new Date(),
          inquiryStatus: 'PENDING',
          orderFormId: orderFormId,
          dueDate: orderFormQuestions?.find(
            (e) =>
              e.questionType === 'DateSelection' &&
              e?.systemQuestion === 'DueDate'
          )?.answer,
          contactInfo: {
            email: orderFormQuestions?.find(
              (e) =>
                e.questionType === 'ShortAnswer' &&
                e.shortAnswerType === 'Email' &&
                e?.systemQuestion === 'Email'
            )?.answer,
            name: orderFormQuestions?.find(
              (e) =>
                e.questionType === 'ShortAnswer' &&
                e.shortAnswerType === 'FullName' &&
                e?.systemQuestion === 'Name'
            )?.answer,
          },
          questionsAnswered: orderFormQuestions?.map((question) => {
            if (
              question.questionType === 'ShortAnswer' &&
              question.shortAnswerType === 'FullName'
            ) {
              const splittedName = question.answer?.trim()?.split(';');
              if (splittedName && splittedName?.length > 0) {
                question.answer = `${splittedName[0]?.split(' ')[0]};${
                  splittedName[splittedName.length - 1]?.split(' ')[0]
                }`;
              }
            }
            if (
              question?.questionType === 'Choice' &&
              question.choiceAnswers === null
            ) {
              question.choiceAnswers = [];
            }

            if (question?.questionType === 'Choice') {
              if (question.choiceAnswers) {
                question.answered = true;
              } else {
                question.answered = false;
              }
            } else if (question.answer) {
              question.answered = true;
            } else {
              question.answered = false;
            }
            return question;
          }),
        };

        if (!body?.dueDate) {
          delete body.dueDate;
        }
        setIsSubmitting(true);
        const accRes = (await API.graphql(
          graphqlOperation(createInquiryPipeline(), {
            input: {
              ...body,
            },
          })
        )) as { data: any };
        setIsSubmitting(false);
        navigate(`/thankyou/${params.slug}`, {
          state: {
            inqId: accRes?.data?.createInquiryPipeline?.inquiryId,
          },
        });
      } else {
        alert(
          'The form is invalid, please correct the highlighted fields and submit again!'
        );
      }
    } catch (error) {
      console.log('error', error);
      navigate(`/notavailable/${params.slug}`);
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    console.log('invalid', invalid);
  }, [invalid]);

  return (
    <Layout isLoading={isLoading} isProUser={isProUser}>
      <>
        <div className={conditionalLayoutClass}>
          <Banner
            setIsLoading={setIsLoading}
            banner={banner}
            accId={accId}
            orderFormId={orderFormId}
          />
          {logoRef && (
            <div className="w-full">
              <div className="product_img bg-gray flex items-center justify-center rounded-full mx-auto -mt-16 overflow-hidden shadow-xl">
                <ProfileImg
                  banner={logoRef}
                  accId={accId}
                  companyName={formDetails.companyName}
                />
              </div>
            </div>
          )}
          <div className="pb-8 w-3/4 lg:w-2/4 mx-auto mt-4">
            <div className="description_top border-b border-robin text-robin font-serif font-normal pointer-events-none	">
              {formDetails.title}
            </div>
            <div
              className="description_low py-2 text-robin font-roboto"
              dangerouslySetInnerHTML={{ __html: formDetails.subtitle }}
            />

            <div className="mx-auto flex flex-col">
              <Stepper title="Customer Details" stepNo="1" />
              {orderFormQuestions?.map((question, index) => {
                return (
                  <Fragment key={index}>
                    {generateQuestion(
                      question,
                      index + 1,
                      accId,
                      orderFormId,
                      (i: number, values: Question) => {
                        const updatedQuestions = orderFormQuestions?.slice();
                        updatedQuestions[i] = {
                          ...values,
                        };
                        setOrderFormQuestions(updatedQuestions);
                      },
                      excludeDateRange,
                      invalid[index]
                    )}
                  </Fragment>
                );
              })}
            </div>
            <div className="flex items-center justify-center mt-12 md:mt-32 mb-16 ">
              <Button
                onClick={() => {
                  createInquiry();
                }}
                loading={isSubmitting}
              />
            </div>
          </div>
        </div>
        <HomeSpinner active={isLoading} />
      </>
    </Layout>
  );
}
